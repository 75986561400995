/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Container,
  Heading,
  Input,
  Stack,
  Text,
  Box,
  Button,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { store } from '@src/store';
import { client } from '@services/management';

export const duration = 2000;

export const Home = () => {
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const handleSubmit = async (
    e:
      | React.FormEvent<
          | HTMLFormElement // if `onContinue` is on the `form`
          | HTMLButtonElement // if `onContinue` is on the `button`
        >
      | React.KeyboardEvent<HTMLElement>
  ) => {
    e.preventDefault();

    if (!clientId && !clientSecret) {
      const { description, status, title } = {
        description: 'Make sure to fill out both fields',
        status: 'warning',
        title: 'Fill all fields',
      } as const;

      toast({
        description,
        duration,
        status,
        title,
      });
    } else {
      try {
        const { description, status, title } = {
          description: 'You are being redirected to your dashboard',
          status: 'success',
          title: 'Valid Credentials',
        } as const;
        const { name } = await client({ clientId, clientSecret });

        store.setState({
          clientId,
          clientSecret,
          name,
        });

        toast({
          description,
          duration,
          status,
          title,
        });

        setTimeout(() => {
          navigate('/client');
        }, duration);
      } catch {
        const { description, status, title } = {
          description: 'Your client credentials are not valid, please re-enter',
          status: 'error',
          title: 'Invalid Credentials',
        } as const;

        toast({
          description,
          duration,
          status,
          title,
        });
      }
    }
  };

  return (
    <Container margin="auto" maxW="62rem" width="85%">
      <Stack
        as={Box}
        textAlign={'center'}
        spacing={{ base: 8, md: 14 }}
        py={{ base: 20, md: 36 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '2xl', md: '6xl', sm: '4xl' }}
          lineHeight={'110%'}
        >
          Welcome to the Affix Developer Dashboard
        </Heading>
        <Text>Enter your Client ID and Client Secret to get started: </Text>

        <Stack direction={'column'} spacing={3} align={'center'}>
          <Input
            placeholder="Client ID"
            onChange={(e) => setClientId(e.target.value)}
          />
          <Input
            placeholder="Client Secret"
            onChange={(e) => setClientSecret(e.target.value)}
          />
          <Button marginTop="1.5rem" type="submit" onClick={handleSubmit}>
            Enter
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

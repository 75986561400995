import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

import { App } from '@src/App';
import { env } from '@src/lib/utils';

Sentry.init({
  dsn:
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    'https://1cad7a9280f620291d462ef1c4ad11f9@o4507062600597504.ingest.de.sentry.io/4507062697721936' || // eu
    'https://3439772712d3c3171662f4e2e98e3ead@o1102369.ingest.us.sentry.io/4507007259836416', // us
  environment: env,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllText: false,
    }),
  ],

  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/dev\.api\.affixapi\.com/,
    /^https:\/\/api\.affixapi\.com/,
    /^https:\/\/affixapi\.com/,
  ],

  tracesSampleRate: 0.05,
});

const container = document.getElementById('root');

const root = createRoot(container!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider>
          <App />
        </ChakraProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

import React from 'react';
import { Flex, Link, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import * as api from '@affixapi/api';

import { store } from '@src/store';
import { MainLayout } from '@src/components/layouts/MainLayout';
import { Loading } from '@src/components/Loading';
import { formatDate } from '@lib/utils';
import { tokens } from '@services/management';
import { Error } from '@src/components/Error';

export const Tokens = () => {
  const clientId = store((state: any) => state.clientId);
  const clientSecret = store((state: any) => state.clientSecret);

  const { isLoading, error, data } = useQuery({
    queryFn: async () => tokens({ clientId, clientSecret }),
    queryKey: ['tokenData'],
  });

  console.log('this is returning from data ' + JSON.stringify(data));
  if (isLoading) return <Loading></Loading>;

  if (error) return <Error error={error}></Error>;

  return (
    <MainLayout>
      <Stack spacing="2rem">
        {data && Array.isArray(data)
          ? data.map((item: api.v20230301.TokensResponse[number]) => (
              <Flex
                direction="column"
                backgroundColor="#ffffff"
                borderRadius="0.5rem"
                boxShadow="base"
                p="6"
                rounded="md"
                padding="2rem"
              >
                <Text
                  as="b"
                  alignSelf="center"
                  marginBottom="1rem"
                  color="gray.400"
                >
                  {item.token}
                </Text>
                <Flex direction="row">
                  <Flex
                    direction="column"
                    marginRight="auto"
                    alignSelf="center"
                  >
                    <Text>
                      Created:
                      {
                        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                        ` ${formatDate(item.created_at!)}`
                      }
                    </Text>
                    <Text>Mode: {item.mode} </Text>
                    <Text> Provider: {item.provider} </Text>
                  </Flex>

                  <Flex alignSelf="center">
                    <Link>View scope(s)</Link>
                  </Flex>
                </Flex>
              </Flex>
            ))
          : null}
      </Stack>
    </MainLayout>
  );
};

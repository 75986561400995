import * as utils from '@affixapi/utils';

import { ApiUrl, UseLive } from '@lib/types';

export const apiVersion = utils.sharedConsts.api.apiVersionLatest;

export const apiUrl = utils.lookup.getLocalValue<ApiUrl>('REACT_APP_API_URL');

export const useLive =
  utils.lookup.getLocalValue<UseLive>('REACT_APP_USE_LIVE') === 'true'; // env var here is a string unfortunately

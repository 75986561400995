import * as api from '@affixapi/api';

export const client: api.v20230301.ClientResponse = {
  client_id: '3FDAEDF9-1DCA4F54-87949F6A-41027643',
  client_secret: ['3FDAEDF9-1DCA4F54-87949F6A-41027643'],
  name: 'Canaries',
  redirect_uris: ['https://affixapi.com'],
  webhook_uri: 'https://webhook.site/8e884d7b-14bf-4730-ba6f-f5be97e84f23',
};

export const tokens: api.v20230301.TokensResponse = [
  {
    created_at: '2023-11-07T16:00:42.345Z',
    mode: 'official',
    provider: 'sandbox',
    scopes: ['/2023-03-01/official/employees', '/2023-03-01/official/identity'],
    token: '446b2012-680c-473f-85f5-189a8ec4a085',
    valid: false,
  },
  {
    created_at: '2024-03-08T15:55:23.769Z',
    mode: 'xhr',
    provider: 'personio.de',
    scopes: [
      '/2023-03-01/xhr/company',
      '/2023-03-01/xhr/employee',
      '/2023-03-01/xhr/employees',
      '/2023-03-01/xhr/identity',
      '/2023-03-01/xhr/payruns',
      '/2023-03-01/xhr/payruns/:payrun_id',
      '/2023-03-01/xhr/time-off-balances',
      '/2023-03-01/xhr/time-off-entries',
      '/2023-03-01/xhr/timesheets',
    ],
    token: '09c451b1-4c0a-4d54-a348-773af74ca4fa',
    valid: true,
  },
  {
    created_at: '2023-12-30T13:23:33.443Z',
    mode: 'xhr',
    provider: 'sandbox',
    scopes: [
      '/2023-03-01/xhr/company',
      '/2023-03-01/xhr/employee',
      '/2023-03-01/xhr/employees',
      '/2023-03-01/xhr/groups',
      '/2023-03-01/xhr/identity',
      '/2023-03-01/xhr/payruns',
      '/2023-03-01/xhr/payruns/:payrun_id',
      '/2023-03-01/xhr/time-off-balances',
      '/2023-03-01/xhr/time-off-entries',
      '/2023-03-01/xhr/timesheets',
      '/2023-03-01/xhr/work-locations',
    ],
    token: '15c69ee5-3e79-41d9-b02f-d823d797079a',
    valid: true,
  },
  {
    created_at: '2024-03-30T13:02:43.221Z',
    mode: 'xhr',
    provider: 'simplepay.ie',
    scopes: [
      '/2023-03-01/xhr/company',
      '/2023-03-01/xhr/employee',
      '/2023-03-01/xhr/employees',
      '/2023-03-01/xhr/groups',
      '/2023-03-01/xhr/identity',
      '/2023-03-01/xhr/payruns',
      '/2023-03-01/xhr/payruns/:payrun_id',
      '/2023-03-01/xhr/time-off-balances',
      '/2023-03-01/xhr/time-off-entries',
      '/2023-03-01/xhr/timesheets',
      '/2023-03-01/xhr/work-locations',
    ],
    token: '6fe8a844-3e22-45db-a8ed-70d4ea06e106',
    valid: true,
  },
];

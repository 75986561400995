import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type Client = {
  clientId: string;
  clientSecret: string;
  name: string;
};

// stores these values in the browser's Local storage
export const store = create(
  persist(
    () =>
      ({
        clientId: '',
        clientSecret: '',
        name: '',
      }) as Client,

    {
      name: 'auth',
    }
  )
);

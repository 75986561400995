import type { AxiosError, AxiosResponse } from 'axios';
// import  { AxiosError,  } from 'axios';
import _get from 'lodash/get';
import axios from 'axios';
import * as api from '@affixapi/api';

import * as shared from '@services/shared';
import * as fixtures from '@services/fixtures';
import type { ClientIdClientSecret } from '@src/lib/types';

const useLive = shared.useLive;
const apiVersion = shared.apiVersion;
const apiUrl = `${shared.apiUrl}/${apiVersion}/management`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const partialAxiosResponse: Omit<AxiosResponse, 'data'> = {
  config: {} as any,
  headers: {} as any,
  status: 200,
  statusText: 'OK',
};

const makeApiRequestRaw = async <
  T extends api.v20230301.ClientResponse | api.v20230301.TokensResponse,
>({
  headers,
  url,
  method,
}: {
  headers?: Record<string, string>;
  method: 'GET' | 'POST';
  url: string;
}): Promise<AxiosResponse<T>> => {
  try {
    return axios.request<T>({
      headers,
      method,
      url,
    });
  } catch (err: any) {
    if (err.isAxiosError) {
      const axiosErr = err as AxiosError;
      const { response: axiosErrPayload } = axiosErr;

      const errMessage = _get(
        axiosErrPayload,
        'data.message',
        'An unexpected error occurred, try again!'
      ) as string;

      throw new Error(errMessage);
    }
    throw err;
  }
};

const makeApiRequest = async <
  T extends api.v20230301.ClientResponse | api.v20230301.TokensResponse,
>({
  clientId,
  clientSecret,
  endpoint,
  method,
}: ClientIdClientSecret & {
  endpoint: 'client' | 'tokens' | 'info';
  method: 'GET' | 'POST';
}): Promise<T> =>
  (
    await makeApiRequestRaw<T>({
      headers: {
        Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`,
      },
      method,
      url: `${apiUrl}/${endpoint}`, // path here not needed, but for cypress interception
    })
  ).data;

export const client = async ({
  clientId,
  clientSecret,
}: ClientIdClientSecret): Promise<api.v20230301.ClientResponse> =>
  // AxiosResponse<api.v20230301.ClientResponse>
  !useLive
    ? fixtures.client
    : // ? {
      // ...partialAxiosResponse,
      // data: fixtures.client,
      // }
      makeApiRequest<api.v20230301.ClientResponse>({
        clientId,
        clientSecret,
        endpoint: 'client',
        method: 'GET',
      });

export const tokens = async ({
  clientId,
  clientSecret,
}: ClientIdClientSecret): Promise<api.v20230301.TokensResponse> =>
  // AxiosResponse<api.v20230301.TokensResponse>
  !useLive
    ? fixtures.tokens
    : // ? {
      // ...partialAxiosResponse,
      // data: fixtures.tokens,
      // }
      makeApiRequest<api.v20230301.TokensResponse>({
        clientId,
        clientSecret,
        endpoint: 'tokens',
        method: 'GET',
      });

/** TODO implement this */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateClientInfo = async ({
  clientId,
  clientSecret,
}: ClientIdClientSecret & {
  clientData: Record<string, string>;
}): Promise<api.v20230301.ClientResponse> =>
  // }): Promise<AxiosResponse<api.v20230301.ClientResponse>> =>
  !useLive
    ? fixtures.client
    : // ? {
      // ...partialAxiosResponse,
      // data: fixtures.client,
      // }
      makeApiRequest<api.v20230301.ClientResponse>({
        clientId,
        clientSecret,
        endpoint: 'client',
        method: 'POST',
      });

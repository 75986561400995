import { Env } from '@lib/types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const sleep = ({ ts, tsInMillis }: { ts: number; tsInMillis: true }) =>
  new Promise((resolve) => setTimeout(resolve, ts));

export const formatDate = (date: string) => {
  const dt = new Date(date);
  return dt.toLocaleDateString('en-gb', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const getLocalValue = <environmentVariables>(
  name: environmentVariables
): string | undefined => {
  const value = process.env[String(name)]; // https://stackoverflow.com/a/46043752

  if (value) return value;

  return undefined;
};

export const env = getLocalValue<Env>('REACT_APP_ENV');
